export const pageView = (page_path) => {
    if (typeof window.gtag !== 'undefined') {
        window.gtag('config', 'G-5KTKWSZ0W5', {
            page_path,
        });
    }
};
export const event = (action, params) => {
    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', action, params);
    }
};
