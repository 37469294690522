import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { theme } from '../shared/theme';
import { Header, Footer } from '../shared/components';
import { LandingPage, AboutPage, ConfiguratorTemplate } from './components';
import ErrorBoundary from '../shared/components/ErrorBoundary/ErrorBoundary';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageView } from '../shared/utils/analytics';
const AppContent = () => {
    const location = useLocation();
    useEffect(() => {
        pageView(location.pathname + location.search);
    }, [location]);
    return (_jsxs("div", { className: "app", children: [_jsx(Header, {}), _jsx("main", { className: "main-content", children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(LandingPage, {}) }), _jsx(Route, { path: "/about", element: _jsx(AboutPage, {}) }), _jsx(Route, { path: "/configurator", element: _jsx(ConfiguratorTemplate, {}) })] }) }), _jsx(Footer, {})] }));
};
const App = () => {
    return (_jsx(ErrorBoundary, { children: _jsx(MantineProvider, { theme: theme, children: _jsx(Router, { children: _jsx(AppContent, {}) }) }) }));
};
export default App;
