import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
            error: null // Initialize as null instead of undefined
        };
    }
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error
        };
    }
    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary: ", {
            error,
            errorInfo,
            location: window.location.href
        });
    }
    render() {
        if (this.state.hasError && this.state.error) { // Check for both
            return this.props.fallback || (_jsxs("div", { style: { padding: '20px', textAlign: 'center' }, children: [_jsx("h1", { children: "Something went wrong" }), _jsx("p", { children: "Please try refreshing the page" }), process.env.NODE_ENV !== 'production' && (_jsx("pre", { children: this.state.error.message }) // Now TypeScript knows error is not null
                    )] }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
