import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import './LandingPage.css';
const LandingPage = () => {
    const configuratorUrl = 'https://design.spinlio.com';
    useEffect(() => {
        // Prefetch critical configurator resources when landing page is idle
        const prefetchConfigurator = () => {
            const criticalBundles = [
                '/vendors.bundle.js',
                '/framework.bundle.js',
                '/main.bundle.js',
                '/shapediver.chunk.js',
                '/three.chunk.js'
            ];
            criticalBundles.forEach(bundle => {
                const link = document.createElement('link');
                link.rel = 'prefetch';
                link.as = 'script';
                link.href = `${configuratorUrl}${bundle}`; // Add configurator URL
                document.head.appendChild(link);
            });
        };
        // Use requestIdleCallback to not impact landing page performance
        if ('requestIdleCallback' in window) {
            requestIdleCallback(() => prefetchConfigurator());
        }
        else {
            // Fallback for browsers that don't support requestIdleCallback
            setTimeout(prefetchConfigurator, 1000);
        }
    }, []);
    const handleDesignClick = (e) => {
        e.preventDefault();
        // Store a flag that we're coming from landing
        sessionStorage.setItem('from_landing', 'true');
        window.location.href = configuratorUrl;
    };
    return (_jsx("div", { className: "landing-page", children: _jsxs("div", { className: "landing-content", children: [_jsxs("div", { className: "text-container", children: [_jsx("h1", { className: "main-title", children: "3D Design - Made Simple" }), _jsx("div", { className: "subtitle-container", children: _jsx("span", { className: "subtitle", children: "Beta V0.1" }) })] }), _jsx("a", { href: configuratorUrl, onClick: handleDesignClick, className: "design-button", children: "Design Now" })] }) }));
};
export default LandingPage;
