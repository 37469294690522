import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Header.css';
const Header = () => {
    // Remove all environment and hostname checks
    // Just hardcode all production URLs
    const getHomeUrl = () => 'https://spinlio.com';
    const getContactUrl = () => 'https://contact.spinlio.com';
    return (_jsx("header", { className: "header", children: _jsxs("div", { className: "header-content", children: [_jsx("a", { href: getHomeUrl(), className: "logo", children: "spinlio" }), _jsxs("nav", { className: "nav-links", children: [_jsx("a", { href: `${getHomeUrl()}/about`, children: "About" }), _jsx("a", { href: getContactUrl(), children: "Contact us" })] })] }) }));
};
export default Header;
