import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Container, Text } from '@mantine/core';
import './Footer.css';
const Footer = () => {
    useEffect(() => {
        // Load Klaviyo script with your company ID
        const script = document.createElement('script');
        script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UYRE7k';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);
    return (_jsx("footer", { className: "footer", children: _jsxs(Container, { size: "xl", className: "footer-content", children: [_jsx(Text, { className: "footer-text", children: "\u00A9 NeuralHub Limited" }), _jsx("div", { className: "newsletter-container", children: _jsx("div", { className: "klaviyo-form-RqHT8c" }) })] }) }));
};
export default Footer;
