// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  overflow-y: auto;
  /* Use relative path to the image in shared assets */
  background-image: url('https://res.cloudinary.com/da8qnqmmh/image/upload/v1730794027/back3back_oye0ev.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Add fade to content instead of body */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

}



.main-content {
  flex: 1;
  display: flex;
  position: relative;
  width: 100%;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto !important;
}

#root {
    min-height: 100%;
    position: relative;
    overflow-y: auto !important;
}
`, "",{"version":3,"sources":["webpack://./src/shared/styles/global.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iCAAiC;EACjC,iBAAiB;EACjB,gBAAgB;EAChB,oDAAoD;EACpD,2GAA2G;EAC3G,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,4BAA4B;AAC9B;;AAEA,wCAAwC;AACxC;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;;AAEpB;;;;AAIA;EACE,OAAO;EACP,aAAa;EACb,kBAAkB;EAClB,WAAW;AACb;;AAEA;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;AAC/B","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Roboto', sans-serif;\n  min-height: 100vh;\n  overflow-y: auto;\n  /* Use relative path to the image in shared assets */\n  background-image: url('https://res.cloudinary.com/da8qnqmmh/image/upload/v1730794027/back3back_oye0ev.jpg');\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n}\n\n/* Add fade to content instead of body */\n.app {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  position: relative;\n\n}\n\n\n\n.main-content {\n  flex: 1;\n  display: flex;\n  position: relative;\n  width: 100%;\n}\n\nhtml, body {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    overflow-y: auto !important;\n}\n\n#root {\n    min-height: 100%;\n    position: relative;\n    overflow-y: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
