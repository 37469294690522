import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This lives in the STATIC build (Netlify)
import { useEffect } from 'react';
import './ConfiguratorTemplate.css';
import { Header } from '../../../shared/components';
const ConfiguratorTemplate = () => {
    useEffect(() => {
        console.log('ConfiguratorTemplate: Mounted');
        return () => console.log('ConfiguratorTemplate: Unmounted');
    }, []);
    return (_jsxs("div", { className: "app", children: [_jsx(Header, {}), _jsxs("div", { className: "configurator-template", children: [_jsx("div", { className: "share-button-container-configurator", children: _jsx("button", { className: "share-button-configurator", disabled: true, children: "Share" }) }), _jsxs("div", { className: "configurator-content", children: [_jsx("div", { className: "viewer-container", children: _jsx("div", { className: "viewer-placeholder", children: _jsx("img", { src: "https://res.cloudinary.com/da8qnqmmh/image/upload/e_make_transparent:10/v1729757636/BIKE_qa0p3v.gif", alt: "Loading", className: "loading-gif" }) }) }), _jsx("div", { className: "parameter-panel-placeholder", children: _jsx("div", { className: "panel-skeleton" }) })] })] })] }));
};
export default ConfiguratorTemplate;
